import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { Masonry } from "@mui/lab";
import { styled } from "@mui/system";
import avatar from "./avatar.jpg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const App = () => {
  const MyBox = styled(Box)({
    borderRadius: 20,
    padding: 20,
    display: "flex",
    flexDirection: "column",
  });

  const colors = {
    light: "#baabbd",
    dark: "#564e58",
    green: "#9caf88",
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      mt={20}
      px={{ xs: "10%", lg: "20%" }}
    >
      <Masonry
        columns={{ xs: 1, lg: 2 }}
        spacing={3}
        sx={{ maxWidth: "1200px" }}
      >
        <MyBox
          sx={{
            backgroundColor: colors.light,
            textAlign: "center",
            height: 300,
            alignItems: "center",
          }}
        >
          <Avatar
            alt="Eidson Sá"
            src={avatar}
            sx={{ width: 200, height: 200, mt: -15, mb: 6 }}
          />
          <Typography variant="h4" fontWeight={700}>
            Eidson Sá
          </Typography>
        </MyBox>
        <MyBox
          sx={{
            backgroundColor: colors.green,
            height: 600,
            color: "#ffffff",
            cursor: "pointer",
            justifyContent: "space-between",
          }}
          onClick={() => window.open("https://www.linkedin.com/in/eidsonsa/")}
        >
          <LinkedInIcon sx={{ fontSize: 200, alignSelf: "flex-end" }} />
          <Box>
            <Typography variant="h4">Frontend/</Typography>
            <Typography variant="h4">Backend/</Typography>
            <Typography variant="h4">Mobile</Typography>
          </Box>
        </MyBox>
        <MyBox
          sx={{
            backgroundColor: colors.dark,
            height: 700,
            color: "#ffffff",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() =>
            window.open(
              "https://drive.google.com/file/d/1NxVVyywbUZH84Swt2Cckuke4zpesQOG2/view"
            )
          }
        >
          <DescriptionOutlinedIcon
            sx={{
              fontSize: 200,
              stroke: colors.dark,
              strokeWidth: 1,
              ml: -4,
            }}
          />
          <Typography variant="h4">Software </Typography>
          <Typography variant="h4">Engineer</Typography>
        </MyBox>
        <MyBox
          sx={{
            backgroundColor: colors.light,
            height: 400,
            justifyContent: "flex-end",
            alignItems: "center",
            pb: 6,
            cursor: "pointer",
          }}
          onClick={() => window.open("mailto:eidsonsa@gmail.com")}
        >
          <Typography variant="h4">Reach me out :)</Typography>
        </MyBox>
      </Masonry>
    </Box>
  );
};

export default App;
